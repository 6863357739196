<template>
  <v-dialog :value="load"
            @keydown.esc="toggleRegisterForm"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :max-width="($vuetify.breakpoint.sm ? '70%' :
              ($vuetify.breakpoint.md ? '60%' : '35%'))"
            transition="scale-transition" persistent>
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <span class="headline">{{ $t('register.form__header') }}</span>
        <v-spacer></v-spacer>
        <v-btn @click="toggleRegisterForm" icon>
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-alert v-model="alert.display" :type="alert.type"
                     dismissible>
              {{ alert.text }}
            </v-alert>
            <v-text-field v-model="user.name"
                          :label="$t('common.field__name-header')"
                          prepend-inner-icon="person"
                          :rules="[v => !!v || $t('common.field__text-validation-error')]"
                          clearable
            ></v-text-field>
            <v-text-field v-model="user.email"
                          :label="$t('common.field__email-header')"
                          prepend-inner-icon="email"
                          :rules="[v => !!v || $t('common.field__text-validation-error'),
                                   v => email_pattern.test(v) || $t('common.field__email-validation-error')]"
                          clearable
                          required
            ></v-text-field>
            <v-text-field v-model="user.password"
                          :label="$t('common.field__password-header')"
                          @keyup.enter="RegisterUser"
                          prepend-inner-icon="keyboard"
                          :append-icon="visible ? 'visibility_off' : 'visibility'"
                          @click:append="visible=!visible"
                          :type="visible ? 'text' : 'password'"
                          :rules="[v => !!v || $t('common.field__text-validation-error'),
                                   v => (v && v.length >= 6) || $t('common.field__password-validation-error')]"
                          clearable
                          required
            ></v-text-field>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="RegisterUser"
                     :loading="processing"
                     :disabled="!email_pattern.test(user.email) || !(user.password && user.password.length>5)"
                     color="primary" block dark text>
                {{ $t('register.form__header') }}
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';

export default {
  name: 'RegisterDialog',
  props: {
    load: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    processing: false,
    visible: false,
    // Object that represent an alert into the registration form.
    alert: {
      display: false,
      type: 'error',
      text: '',
    },
    // Object that represents an user.
    user: {
      name: '',
      email: '',
      password: '',
      logged_in: false,
    },
    // Pattern to recognize a valid email address.
    // eslint-disable-next-line no-useless-escape
    email_pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    valid: false,
  }),
  methods: {
    /**
     * Register an user into the database.
     * @constructor
     */
    RegisterUser() {
      this.alert.display = false;
      this.processing = true;
      axios.post(`${axios.defaults.baseURL}/user/create`,
          JSON.stringify({
            name: this.user.name,
            email: this.user.email,
            password: this.user.password,
            url: axios.defaults.baseURL
          })).then(() => {
        this.processing = false;
        this.$refs.form.reset();
        this.alert.text = this.$t('register.alert__success');
        this.alert.type = 'success';
        this.alert.display = true;
      }).catch(err => {
        this.processing = false;
        this.user.password = '';
        if (err.response.status === 409) {
          this.alert.text = this.$t('register.alert__warning');
          this.alert.type = 'orange';
          this.alert.display = true;
        } else {
          this.alert.text = this.$t('register.alert__error');
          this.alert.type = 'error';
          this.alert.display = true;
        }
      });
    },
    /**
     * Clear the registration information and emits globally to close the popup.
     */
    toggleRegisterForm() {
      this.$refs.form.reset();
      this.alert.display = false;
      this.$store.dispatch("toggleForm", {name: "register", value: false})
    },
  },
};
</script>

<style scoped>

</style>
